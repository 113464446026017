import React from 'react';
import TwoColumnGrid from '../../components/StyledGrids/two_column_grid';
import WhereYouLive from './whereYouLive';
import WhereYouWork from './whereYouWork';
import heroImage from '../../assets/laurie_home_flipped_cropped.jpg';
import ServicesList from '../../components/services-list';
import { Grid, Typography } from '@mui/material';

export default function Home() {
  return (
    <>
      <div className="homeHeroImage" style={{ backgroundImage: `url(${heroImage})` }} />
      <div className='contentFrame'>
        <Grid container sx={{padding: '0 20px' }}>
          <Typography variant='body1'>Plants add so much to our lives and interior spaces.  I simply love the way they transform a house into a home. I am always amazed at how even one well-tended plant can lend life and vibrancy to where we shop, work and wait.  I have spent years learning to keep a wide array of plants thriving in my home.  Redhead Green Thumb is how I have decided to share my passion to make my corner of the world a little greener.</Typography>
        </Grid>
        <TwoColumnGrid leftContent={<WhereYouWork />} rightContent={<WhereYouLive />} spaceBetween />
        <ServicesList />
      </div>
    </>
  );
}
