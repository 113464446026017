import {
  LocalFlorist,
  AttachMoney,
  Schedule,
  Build,
} from '@mui/icons-material';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ColoredTypography, {
  CustomColor,
} from '../../../components/ColoredTypography';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';

interface ProgramFeature {
  icon: React.ElementType;
  title: string;
  description?: ReactNode;
  color: string;
}

const WowNow: React.FC = () => {
  const programFeatures: ProgramFeature[] = [
    {
      icon: LocalFlorist,
      title: 'Plant Selection',
      description:
        'Choose from a curated selection of plants, including popular indoor varieties, low-maintenance options, and specialty plants that will thrive in your space.',
      color: 'primary',
    },
    {
      icon: AttachMoney,
      title: 'Affordable Payments',
      description:
        'Monthly payments contribute toward the eventual ownership of the plant.',
      color: 'primary',
    },
    {
      icon: Schedule,
      title: 'Flexible Terms',
      description: 'Enjoy flexible rental periods tailored to your needs.',
      color: 'blue.800',
    },
    {
      icon: Build,
      title: 'Maintenance Included',
      description: (
        <>
          <Link to="plant-maintenance">Plant maintenance services</Link> are
          provided as outlined in our standard care package.
        </>
      ),
      color: 'brown.800',
    },
  ];

  return (
    <div className="contentFrame">
      <Grid container spacing={3} sx={{padding: '0 20px' }}>
        <Grid item xs={12}>
          <Typography variant="h1">Rent-to-Own Plants Program</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" paragraph>
            Adding plants to your space can be an investment. Our "Rent-to-Own
            Plants" program is designed to offer a flexible and affordable way
            to instantly incorporate greenery into your space. This program
            allows you to rent plants with the option to own them over time,
            making plant ownership accessible and financially manageable.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h2">Program Structure</Typography>
        </Grid>

        {programFeatures.map((feature, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card
              elevation={0}
              sx={{ height: '100%', backgroundColor: 'transparent' }}
            >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                  <ColoredTypography
                    color={feature.color as CustomColor}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <feature.icon fontSize="large" />
                  </ColoredTypography>
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      {feature.title}
                    </Typography>
                    <Typography variant="body2">
                      {feature.description}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Card elevation={0} sx={{ backgroundColor: 'transparent' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Rental Terms
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <AttachMoney color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Deposit"
                    secondary="30% of purchase price, with a $100 rent-to-own fee"
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default WowNow;
