import { createTheme, Theme, ThemeOptions} from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    white: string;
    blue: Palette['grey'];
    yellow: Palette['grey'];
    brown: Palette['grey'];
  }
  interface PaletteOptions {
    white: string;
    blue: PaletteOptions['grey'];
    yellow: PaletteOptions['grey'];
    brown: PaletteOptions['grey'];
  }
}
const breakpointTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});


const colors = {
  red: '#7B2A17',
  green: '#00954D',
  lightGreen: '#A4FFCD',
  grey: '#6C6C6F',
  lightGrey: '#C8C8CC',
  white: '#FFF',
  blue: '#2E3192',
  lightBlue: '#5456B7',
  yellow: '#FFCE07',
  lightYellow: '#FFE573',
  darkYellowBrown: '#B8860B',
  darkBrown: '#8B4513',
  brown: '#A0522D',
  paleBrown: '#D2B48C',
};

const themePalette = {
  primary: {
    main: colors.green,
  },
  secondary: {
    main: colors.red,
  },
  text: {
    primary: colors.grey,
  },
  background: {
    default: colors.white,
    paper: colors.lightGrey,
  },
  grey: {
    800: colors.grey,
    400: colors.lightGrey,
  },
  blue: {
    800: colors.blue,
    400: colors.lightBlue,
  },
  yellow: {
    800: colors.yellow,
    400: colors.lightYellow,
    200: colors.darkYellowBrown
  },
  brown: {
    800: colors.brown,
    400: colors.paleBrown,
    200: colors.darkBrown
  },
  white: colors.white
};

const createThemeTypography = (theme: Theme): ThemeOptions["typography"] => ({
  fontFamily: 'Roboto, sans-serif',
  h1: {
    fontWeight: 300,
    fontSize: '4rem',
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
    '& small': {
      display: 'block',
      fontWeight: 300,
      fontSize: '2rem',
      letterSpacing: '0.125em',
      marginBottom: '0.125rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
  },
  h2: {
    fontWeight: 900,
    fontSize: '1.25rem',
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
  },
  allVariants: {
    color: colors.grey,
  },
});

const theme = createTheme({
  ...breakpointTheme,
  typography: createThemeTypography(breakpointTheme),
  palette: themePalette,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          margin: 0;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.green,
          color: colors.lightGreen,
          '&:hover': {
            backgroundColor: colors.green,
            color: colors.white,            
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 700, // Bold
        },
      },
    },
  },
});
export default theme;
